import FirstScreen from "~/components/Enga/Components/FirstScreen";
import AutopromoContainer from "~/components/Enga/Components/AutopromoContainer";
import imgProxy from "~/utils/imgproxy";
import { urlRs } from "~/utils/url";
import { useMediaOverlayContext } from "~/contexts/MediaOverlayContext";

import "./EngaPages.css";
import "./EngaSinglePage.css";

import IconArrowForward from "~/img/icons/arrow_forward.svg";
import IconPlayArrow from "~/img/icons/play_arrow.svg";
import GtmVars from "../shared/Trackers/GtmVars";

export default function Enga8Page() {
  const gtmValues = () => {
    return {
      "all.pageType": "other",
      "all.mainCategory": "Engagement 8",
      "all.category": "Non défini",
      "all.subCategory": "Non défini",
      "all.subsubCategory": "Non défini",
      "all.subsubsubCategory": "Non défini",
      template: "nos-engagements",
    };
  };

  return (
    <>
      <GtmVars values={gtmValues()} />
      <article class="page-engagement page-engagement-8">
        <FirstScreen
          visual="/images/engagements/page-engagement-8-visual.jpg"
          visualMobile="/images/engagements/page-engagement-8-visual-mobile.jpg"
          title="Qualité et pérennité des constructions"
          prevLink="/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/le-respect-de-la-biodiversite.html"
          nextLink="/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/des-batiments-plus-respectueux-de-l-environnement.html"
        />

        <EngaDetails />
        <section class="engagement-rows">
          <h2>Les preuves de nos engagements</h2>
          <EngaRow1 />
          <EngaRow2 />
          <EngaRow3 />
        </section>

        <section class="next-nav">
          <a
            href={urlRs(
              "enga",
              "/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/des-batiments-plus-respectueux-de-l-environnement.html",
            )}
            class="btn"
          >
            Voir l'engagement suivant{" "}
            <i aria-hidden="true" class="cog-icon">
              <IconArrowForward />
            </i>
          </a>
        </section>
      </article>

      <AutopromoContainer />
    </>
  );
}

function EngaDetails() {
  const [, { setMediaOverlay }] = useMediaOverlayContext();

  return (
    <>
      <section class="engagement-details">
        <div class="introduction">
          <div class="tags">
            <span>Pour l’environnement</span>
          </div>
          <p>
            Construire des biens qui font du bien, c’est vous proposer des
            projets certifiés NF Habitat, conçus avec des matériaux durables et
            respectueux de l’environnement.
          </p>
        </div>

        <div class="media-part">
          <div class="visual">
            <img
              fetchpriority="high"
              src={imgProxy(
                "/images/engagements/page-engagement-8-video.jpg",
                `size:690:442/resizing_type:fill`,
              )}
              alt=""
              height="240"
              width="375"
              loading="lazy"
            />
            <button
              class="btn btn-icon"
              onClick={() => {
                setMediaOverlay(
                  "embed",
                  `<iframe height="100%" width="100%" src="https://www.youtube.com/embed/-otlNbOkh4U" allowfullscreen />`,
                );
              }}
            >
              <i aria-hidden="true" class="cog-icon">
                <IconPlayArrow />
              </i>
            </button>
          </div>
          <div class="video-details">
            <p class="blockquote-introduction">
              Dominique Bidou, Président d'Honneur de l'association HQE - GBC,
              nous raconte comment le développement durable agit autant sur la
              planète, que sur le bien-être personnel.
            </p>
            <blockquote>
              <p>
                Le développement durable est une source inépuisable
                d’opportunités en associant qualité de vie et respect de la
                planète, spécialement dans le logement.
              </p>
            </blockquote>
          </div>
        </div>
      </section>
    </>
  );
}

function EngaRow1() {
  return (
    <>
      <div class="engagement-row">
        <div class="text-part">
          <h3>Des immeubles pérennes</h3>
          <p>
            Cogedim apporte un soin particulier aux matériaux de l’enveloppe des
            bâtiments afin que ceux-ci restent esthétiques dans le temps (par
            exemple avec la protection systématique des balcons vis-à-vis des
            infiltrations).
          </p>
          <p>
            Les détails techniques sont toujours élaborés en interne, afin de
            garantir la qualité Cogedim à ses clients depuis plus de 50 ans.
          </p>
        </div>
        <picture class="visual-part full">
          <img
            src={imgProxy(
              "/images/engagements/page-engagement-8-visuel1.jpg",
              `size:960:1080/resizing_type:fill`,
            )}
            alt=""
            height="422"
            width="375"
            loading="lazy"
          />
        </picture>
      </div>
    </>
  );
}

function EngaRow2() {
  return (
    <>
      <div class="engagement-row">
        <div class="text-part">
          <h3>Des fournisseurs responsables</h3>
          <p>
            Les équipements et revêtements de sol et de murs que nous
            fournissons à nos clients dans le cadre de nos collections
            proviennent de fabricants situés sur le territoire européen,
            sélectionnés en fonction de leur politique d’achats responsables, et
            de la qualité de leur produits, validées par l’organisme ECOVADIS.
          </p>
          <p>
            Les produits sont sélectionnés en fonction des critères
            suivants&nbsp;: esthétique, durabilité, facilité d'entretien et
            conformité aux exigences du référentiel NF Habitat HQE.
          </p>
        </div>
        <picture class="visual-part full">
          <img
            src={imgProxy(
              "/images/engagements/page-engagement-8-visuel2.jpg",
              `size:960:1080/resizing_type:fill`,
            )}
            alt=""
            height="422"
            width="375"
            loading="lazy"
          />
        </picture>
      </div>
    </>
  );
}

function EngaRow3() {
  return (
    <>
      <div class="engagement-row">
        <div class="text-part">
          <h3>100 % de nos projets sont certifiés NF Habitat</h3>
          <p>
            Cogedim est un des rares promoteurs nationaux agréé NF Habitat,
            Haute Qualité Environnementale maturité 3, le niveau maximal de
            cette certification&nbsp;:
          </p>
          <ul>
            <li>
              l’organisme indépendant de certification Cerqual atteste de la
              qualité des logements et leur processus de fabrication
              sécurisé&nbsp;;
            </li>
            <li>
              Cerqual audite les matériaux utilisés, la conception des logements
              et des immeubles, le confort d’usage, et les performances en
              matière de thermique, d’acoustique et de qualité de l’air&nbsp;;
            </li>
            <li>
              des plans de contrôle stricts adaptés à la complexité des projets,
              afin de prévenir les désordres et les non-conformités de tous
              ordres, et garantir un strict respect des performances techniques
              et réglementaires visées&nbsp;;
            </li>
            <li>
              des audits externes annuels et des audits blancs internes continus
              permettent de vérifier de manière permanente les performances de
              l’organisation et la conformité de la production à nos engagements
              de tous ordres.
            </li>
          </ul>
        </div>
        <picture class="visual-part full">
          <img
            src={imgProxy(
              "/images/engagements/page-engagement-8-visuel3.jpg",
              `size:960:1080/resizing_type:fill`,
            )}
            alt=""
            height="422"
            width="375"
            loading="lazy"
          />
        </picture>
      </div>
    </>
  );
}
